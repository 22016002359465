<template>
  <div id="app">
    <Menu />
    <Confirm />
    <span class="show-menu-btn" @click="toggleMenu">
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>hamburger</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="hamburger" transform="translate(0.000000, 0.640000)" stroke="#000F7E">
                <line x1="0" y1="0.5" x2="19" y2="0.5" id="Path"></line>
                <line x1="19" y1="18.5" x2="0" y2="18.5" id="Path" stroke-width="0.97"></line>
                <line x1="0" y1="9.5" x2="19" y2="9.5" id="Path" stroke-width="0.97"></line>
            </g>
        </g>
    </svg>
    </span>
    <div @click="hideMenu" class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from './components/Menu'
import {FETCH_PAGE_CONTENT} from './store/actions'
import {
  HIDE_MENU,
  RESET_LAST_ROUTE, RESET_MESSAGE_SCREEN, RESET_STAGE,
  SET_DESKTOP,
  SET_LAST_ROUTE,
  SET_MOBILE,
  TOGGLE_MENU
} from '@/store/mutations'
import {mapGetters} from 'vuex'
import Confirm from '@/components/Confirm'

export default {
  name: 'App',
  components: {
    Confirm,
    Menu
  },
  computed: {
    ...mapGetters(['showMenu', 'seminarStage', 'isMobile'])
  },
  mounted() {
    this.$store.dispatch(FETCH_PAGE_CONTENT, this.$route.params.hash || '')
    this.$store.commit(RESET_STAGE)
    setTimeout(() => {
      this.setLastRoute(this.$route)
    }, 200)
  },
  watch: {
    $route(to, from) {
      this.setLastRoute(from)
    }
  },
  methods: {
    setLastRoute(route) {
      if (route.name !== 'any' && route.name !== 'staticPage' && route.name !== 'contact' && route.name !== '404' && this.seminarStage !== 'invalid') {
        this.$store.commit(SET_LAST_ROUTE, route.path)
      }
    },
    reset() {
      this.$store.commit(RESET_LAST_ROUTE)
      this.$store.commit(RESET_MESSAGE_SCREEN)
    },
    toggleMenu() {
      this.$store.commit(TOGGLE_MENU)
    },
    hideMenu() {
      if (this.showMenu) {
        this.$store.commit(HIDE_MENU)
      }
    },
    handleResize() {
      if (window.innerWidth < 768 && !this.isMobile) {
        this.$store.commit(SET_MOBILE)
      } else if (window.innerWidth >= 768 && this.isMobile) {
        this.$store.commit(SET_DESKTOP)
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    window.addEventListener('beforeunload', this.reset)
  }
}
</script>

<style lang="scss">
@import "styles/app";
#app {
  .show-menu-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    cursor: pointer;
    display: block;
    margin-bottom: 100px;
    z-index: 9;
  }
}
</style>
