<template>
  <div class="imgemo-end">
    <TeamInfo :title="imgemo.end_text" :image="imgemo.end_image" />
    <Navigation :show-prev="false" next-page="threepStart" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'ImgemoEnd',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['imgemo'])
  }
}
</script>

<style scoped>

</style>