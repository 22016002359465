<template>
  <div class="menu" :class="showMenu ? 'show-menu' : ''">
    <span class="close-btn" @click="toggleMenu">
      <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>close</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gruppe_2" transform="translate(0.660000, 0.630000)" stroke="#A7D4EB">
            <line x1="19.09" y1="8.8817842e-16" x2="1.42108547e-14" y2="19.09" id="Linie_3"></line>
            <line x1="1.42108547e-14" y1="8.8817842e-16" x2="19.09" y2="19.09" id="Linie_4"></line>
        </g>
    </g>
  </svg>
    </span>
    <ul class="nav">
      <router-link
        v-for="(item, index) in pages"
        :key="`menu-item-${index}`"
        :to="{ path: `/${item.slug}` }"
        @click.native="toggleMenu"
        tag="li"
        class="menu-item"
      >
        <a v-text="item.title"></a>
      </router-link>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {TOGGLE_MENU} from '@/store/mutations'

export default {
  name: 'Menu',
  computed: {
    ...mapGetters(['showMenu', 'staticPages']),
    pages() {
      let pages = this.staticPages
      const contactPage = {
            title: 'Schreib der Villa',
            slug: 'contact'
          }
      pages.unshift(contactPage)
      return pages
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit(TOGGLE_MENU)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
@import "src/styles/partials/variables";
.menu {
  height: 100vh;
  background-color: $darkBlue;
  position: fixed;
  width: 100%;
  line-height: 44px;
  font-size: 30px;
  color: $textLightBlue;
  letter-spacing: 0.3px;
  transition: .5s ease-in-out;
  z-index: 9;
  transform: translate(-100%, 0);
  &.show-menu {
    transform: translate(0, 0);
    transition: .5s ease-in-out;
  }
  @media (min-width: $smartphone) {
    width: 330px;
  }
  .nav {
    margin-top: 111px;
    padding-left: 69px;
    li {
      border-bottom: 1px solid $textLightBlue;
      list-style: none;
      padding: 5px;
      a {
        color: $textLightBlue;
        letter-spacing: 0.3px;
        text-decoration: none;
      }
    }
  }
  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}
</style>