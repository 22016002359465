<template>
  <div class="post-end">
    <h1>Vielen Dank für Ihre Teilnahme. Bis bald!</h1>
    <Navigation prev-page="social" :show-next="false" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import {mapGetters} from 'vuex'
export default {
  name: 'PostEnd',
  components: {Navigation},
  computed: {
    ...mapGetters(['seminarStage'])
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'post' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.post-end {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  h1 {
    width: 100%;
    @media (min-width: $tablet) {
      width: 30%;
    }
  }
}

</style>