<template>
  <div class="navigation">
    <div class="navigation-item prev" @click="$emit('prev'); navigate('prev')" v-if="showPrev">
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>arrow_left</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="arrow_left" fill="#000F7E" fill-rule="nonzero" points="19 19.64 0 10.14 19 0.64"></polygon>
        </g>
      </svg>
    </div>
    <div class="navigation-item next" @click="$emit('next'); navigate('next')" v-if="showNext" :class="btnStyle">
      <span class="navigation-item-title">{{ title }}</span>
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>arrow_right</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="arrow_right" fill="#000F7E" fill-rule="nonzero" points="0 19.64 19 10.14 0 0.64"></polygon>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    title: {
      type: String,
      default: 'weiter'
    },
    nextPage: {
      type: String,
      default: '',
    },
    prevPage: {
      type: String,
      default: '',
    },
    showPrev: {
      type: Boolean,
      default: true
    },
    showNext: {
      type: Boolean,
      default: true
    },
    disabledNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    btnStyle() {
      return this.disabledNext ? 'disabled' : ''
    }
  },
  methods: {
    navigate(e) {
      if (this.$route.name !== this.nextPage) {
        if (e === 'next' && !this.disabledNext) {
          this.$router.push({ name: this.nextPage }).catch(() => {})
        } else {
          this.$router.push({ name: this.prevPage }).catch(() => {})
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
@import "src/styles/partials/variables";
.navigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  .navigation-item {
    padding: 20px;
    font-size: 34px;
    color: $darkBlue;
    cursor: pointer;
    &.prev {
      flex: 1;
    }

    .navigation-item-title {
      margin-right: 12px;
    }
  }
  .next {
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

</style>