<template>
  <div class="slider">
    <div class="slider-options">
      <span>{{ leftOption }}</span>
      <span>{{ rightOption }}</span>
    </div>
    <vue-slider
        ref="slider"
      class="slide"
      :tooltip="'none'"
      v-model="points"
      @change="onChangeSlider"
    ></vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  name: 'Slider',
  components: {VueSlider},
  data() {
    return {
      points: this.value
    }
  },
  props: {
    leftOption: {
      type: String,
      default: ''
    },
    rightOption: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 50
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChangeSlider() {
      this.$emit('change', this.points, this.title, this.id)
    },
  },
  watch: {
    $route() {
      this.points = this.value
    }
  },
  mounted() {
    const num = parseInt(this.id.slice(-1))
    this.points = num % 2 === 0 ? 100 : 0
    setTimeout(() => {
      this.points = this.value
    }, 100)
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.slider {
  margin-bottom: 2px;
  padding: 13px;
  max-width: 362px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $tablet) {
    padding: 13px 20px;
  }
  .slide,
  .vue-slider,
  .vue-slider-ltr {
    width: 80% !important;
    padding: 0 !important;
    margin-bottom: 28px !important;
  }
  .vue-slider:hover {
    .vue-slider-process {
      background: $darkBlue;
      cursor: pointer;
    }
  }
  .vue-slider-process {
    background: $darkBlue;
    height: 2px !important;
    width: 100% !important;
    &:hover {
      background: $darkBlue !important;
    }
  }

  .vue-slider-dot {
    width: 15px !important;
    height: 15px !important;
    .vue-slider-dot-handle {
      border: none !important;
      background-color: $darkBlue;
    }
    .vue-slider-dot-handle-focus {
      box-shadow: none !important;
    }
  }

  .slider-options {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 20px;
      color: $darkBlue;
    }
  }
}


</style>