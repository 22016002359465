<template>
  <div class="static-page">
    <h1 v-html="currentStaticPage.title"></h1>
    <p v-html="currentStaticPage.text"></p>
    <span class="close-static" @click="closeStatic" v-if="lastRoute !== ''">
      <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>close</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gruppe_2" transform="translate(0.660000, 0.630000)" stroke="#000F7E">
            <line x1="19.09" y1="8.8817842e-16" x2="1.42108547e-14" y2="19.09" id="Linie_3"></line>
            <line x1="1.42108547e-14" y1="8.8817842e-16" x2="19.09" y2="19.09" id="Linie_4"></line>
        </g>
    </g>
  </svg>
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'StaticPage',
  computed: {
    ...mapGetters(['currentStaticPage', 'lastRoute'])
  },
  methods: {
    closeStatic() {
      this.$router.push(this.lastRoute)
    }
  },
}
</script>

<style lang="scss">
@import "src/styles/app";
.static-page {
  min-height: 100vh;
  background-color: $lightBlue;
  padding: 34px;
  position: relative;
  h1 {
    font-size: 30px;
    font-weight: 300;
    padding: 30px 0;
  }
  p {
    font-size: 20px;
  }
  .close-static {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

</style>
