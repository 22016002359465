<template>
  <div class="threep-end page-content">
    <TeamInfo :title="threep.end_text" :image="threep.end_image" />
    <Navigation @prev="prevPage" next-page="preEnd" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'ThreepEnd',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['threep'])
  },
  methods: {
    prevPage() {
      this.$router.push({ name: 'perspectives', params: { id: (3).toString() } })
    }
  }
}
</script>

<style scoped>

</style>