<template>
  <div class="social page-content">
    <h1 class="page-title">Bleiben Sie auf dem Laufenden! Folgen Sie uns doch hier:</h1>
    <div class="social-links">
      <ul>
        <li v-for="(link, idx) in links" :key="idx">
          <a :href="link.href" target="_blank"><img :src="link.icon">{{ link.title }}</a>
        </li>
      </ul>
    </div>
    <Navigation @prev="prevPage" next-page="postEnd" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import {mapGetters} from 'vuex'
export default {
  name: 'Social',
  components: {Navigation},
  computed: {
    ...mapGetters(['social', 'seminarStage']),
    links() {
      return [
        { icon: require('@/assets/icons/Facebook.svg'), title: 'Facebook', href: this.social.facebook },
        { icon: require('@/assets/icons/Insagram.svg'), title: 'Instagram', href: this.social.twitter },
        { icon: require('@/assets/icons/Twitter.svg'), title: 'Twitter', href: this.social.instagram },
        { icon: require('@/assets/icons/TikTok.svg'), title: 'TikTok', href: this.social.tiktok },
      ]
    }
  },
  data() {
    return {
      lastRoute: null
    }
  },
  methods: {
    prevPage() {
      this.$router.push(this.lastRoute)
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.lastRoute = from.path
    })
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'post' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.social {
  .page-title {
    text-align: left;
    width: 100%;
    padding-left: 60px;
    @media (min-width: $smartphone) {
      width: 50%;
      padding-left: 0;
    }
    @media (min-width: $tablet) {
      width: 35%;
      padding-left: 0;
    }
    @media (min-width: $desktop) {
      width: 25%;
      padding-left: 0;
    }
  }
  .social-links {
    margin-top: 53px;
    width: 100%;
    padding-left: 60px;
    @media (min-width: $smartphone) {
      width: 50%;
      padding-left: 0;
    }
    @media (min-width: $tablet) {
      width: 35%;
      padding-left: 0;
    }
    @media (min-width: $desktop) {
      width: 25%;
      padding-left: 0;
    }
    ul {
      li {
        list-style: none;
        margin-bottom: 30px;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
          color: $darkBlue;
          img {
            width: 66px;
            height: 66px;
            margin-right: 23px;
          }
        }
      }
    }
  }
}

</style>