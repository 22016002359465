import Vue from 'vue'
import Vuex from 'vuex'
import {getters} from './getters'
import {state} from './state'
import {mutations, SELECT_EMOTICON, SELECT_IMAGE, SET_PAGE_CONTENT, SET_RATINGS} from './mutations'
import {actions} from './actions'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => state,
  filter: (mutations) => mutations.type === SET_PAGE_CONTENT || SELECT_IMAGE || SELECT_EMOTICON || SET_RATINGS
})

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [vuexLocal.plugin]
})
