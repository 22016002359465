<template>
  <div class="team-start page-content">
    <TeamInfo :title="team.start_text" :image="team.start_image" />
    <Navigation prevPage="teamIntro" nextPage="teamVideo" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'TeamStart',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['team'])
  }
}
</script>

<style scoped>

</style>