import Vue from 'vue'
import Router from 'vue-router'
import Intro from '@/pages/Intro'
import Team from '@/pages/Team'
import TeamIntro from '@/pages/Team/Intro'
import TeamStart from '@/pages/Team/TeamStart'
import TeamVideo from '@/pages/Team/TeamVideo'
import TeamEnd from '@/pages/Team/TeamEnd'
import Imgemo from '@/pages/Imgemo'
import ImgemoStart from '@/pages/Imgemo/ImgemoStart'
import ImgemoGallery from '@/pages/Imgemo/ImgemoGallery'
import ImgemoSelected from '@/pages/Imgemo/ImgemoSelected'
import ImgemoEmoticons from '@/pages/Imgemo/ImgemoEmoticons'
import ImgemoEnd from '@/pages/Imgemo/ImgemoEnd'
import Threep from '@/pages/Threep'
import ThreepStart from '@/pages/Threep/ThreepStart'
import ThreepIntro from '@/pages/Threep/ThreepIntro'
import ThreepIntroVideo from '@/pages/Threep/ThreepIntroVideo'
import ThreepQuestion from '@/pages/Threep/ThreepQuestion'
import ThreepPerspectives from '@/pages/Threep/ThreepPerspectives'
import ThreepEnd from '@/pages/Threep/ThreepEnd'
import PreEnd from '@/pages/PreEnd'
import StaticPage from '@/pages/StaticPage'
import Contact from '@/pages/Contact'
import PostMessage from '@/pages/PostMessage'
import Events from '@/pages/Events'
import SendMessage from '@/pages/SendMessage'
import NotFound from '@/pages/NotFound'
import EventsThank from '@/pages/Events/EventsThank'
import EventsPage from '@/pages/Events/EventsPage'
import Social from '@/pages/Social'
import PostEnd from '@/pages/PostEnd'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/seminar/:hash',
      name: 'preIntro',
      component: Intro
    },
    {
      path: '/seminar/:hash/team',
      name: 'team',
      component: Team,
      children: [
        {
          path: '',
          name: 'teamIntro',
          component: TeamIntro
        },
        {
          path: 'start',
          name: 'teamStart',
          component: TeamStart
        },
        {
          path: 'video',
          name: 'teamVideo',
          component: TeamVideo
        },
        {
          path: 'end',
          name: 'teamEnd',
          component: TeamEnd
        }
      ]
    },
    {
      path: '/seminar/:hash/imgemo',
      name: 'imgemo',
      component: Imgemo,
      children: [
        {
          path: '',
          name: 'imgemoStart',
          component: ImgemoStart
        },
        {
          path: 'gallery/:id',
          name: 'imgemoGallery',
          component: ImgemoGallery
        },
        {
          path: 'selected',
          name: 'imgemoSelected',
          component: ImgemoSelected
        },
        {
          path: 'emoticons',
          name: 'emoticons',
          component: ImgemoEmoticons
        },
        {
          path: 'emoticons/:id',
          name: 'selectEmoticon',
          component: ImgemoEmoticons
        },
        {
          path: 'end',
          name: 'imgemoEnd',
          component: ImgemoEnd
        }
      ]
    },
    {
      path: '/seminar/:hash/threep',
      name: 'threep',
      component: Threep,
      children: [
        {
          path: '',
          name: 'threepStart',
          component: ThreepStart
        },
        {
          path: 'intro',
          name: 'threepIntro',
          component: ThreepIntro
        },
        {
          path: 'video',
          name: 'theepVideo',
          component: ThreepIntroVideo
        },
        {
          path: 'question',
          name: 'theepQuestion',
          component: ThreepQuestion
        },
        {
          path: 'perspectives/:id',
          name: 'perspectives',
          component: ThreepPerspectives
        },
        {
          path: 'end',
          name: 'threepEnd',
          component: ThreepEnd
        }
      ]
    },
    {
      path: '/seminar/:hash/end',
      name: 'preEnd',
      component: PreEnd
    },
    {
      path: '/seminar/:hash/message',
      name: 'message',
      component: PostMessage
    },
    {
      path: '/seminar/:hash/send-message',
      name: 'sendMessage',
      component: SendMessage
    },
    {
      path: '/seminar/:hash/events',
      name: 'events',
      component: Events,
      children: [
        {
          path: '',
          name: 'eventsThank',
          component: EventsThank
        },
        {
          path: 'view',
          name: 'eventsView',
          component: EventsPage
        }
      ]
    },
    {
      path: '/social',
      name: 'social',
      component: Social
    },
    {
      path: '/end',
      name: 'postEnd',
      component: PostEnd
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '/:slug',
      name: 'staticPage',
      component: StaticPage
    },
    {
      path: '*',
      name: 'any',
      component: NotFound
    },
  ]
})
export default router
