<template>
  <div class="image-dialog">
    <v-dialog :value="value" @input="$emit('input')" width="auto" overlay-opacity="0.9">
      <div class="fullscreen-image">
        <button class="contract-btn" @click="$emit('close')">
          <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>contract</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="contract" transform="translate(0.500000, 0.640000)" fill="#000F7E" fill-rule="nonzero" stroke="#000F7E" stroke-width="0.25">
                <polygon id="Path" points="3.5 2.8 0.71 0 0 0 0 0.71 2.79 3.5 0 3.5 0 4.5 4.5 4.5 4.5 0 3.5 0"></polygon>
                <polygon id="Path" points="2.79 16.5 0 19.3 0 20 0.71 20 3.5 17.21 3.5 20 4.5 20 4.5 15.5 0 15.5 0 16.5"></polygon>
                <polygon id="Path" points="16.5 17.21 19.29 20 20 20 20 19.3 17.21 16.5 20 16.5 20 15.5 15.5 15.5 15.5 20 16.5 20"></polygon>
                <polygon id="Path" points="17.21 3.5 20 0.71 20 0 19.29 0 16.5 2.8 16.5 0 15.5 0 15.5 4.5 20 4.5 20 3.5"></polygon>
              </g>
            </g>
          </svg>
        </button>
        <img :src="imageSrc" alt="img">
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {assetSrc} from '@/helpers'

export default {
  name: 'ImageDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    src: assetSrc,
    closeDialog() {
      this.value = false
    }
  },
  computed: {
    imageSrc() {
      return Object.keys(this.image).length > 0 ? this.src(this.image.image) : ''
    }
  }
}
</script>

<style  lang="scss">
@import "src/styles/app";
.v-dialog {
  position: relative;
  box-shadow: none !important;
  .fullscreen-image {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
    .contract-btn {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

</style>