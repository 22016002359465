import {
  getPageContent,
  sendAudioMessage,
  sendContactMessage,
  sendImgemo,
  sendPerspectives,
  sendTextMessage, subscribeEvent
} from '../api'
import {
  CLEAR_RATINGS,
  CLEAR_SELECTED_IMAGES,
  SET_PAGE_CONTENT, SET_SEMINAR_HASH,
  SET_SUBSCRIBED_EVENTS, TOGGLE_MESSAGE_SCREEN,
} from './mutations'

export const FETCH_PAGE_CONTENT = 'FETCH_PAGE_CONTENT'
export const SEND_IMGEMO = 'SEND_IMGEMO'
export const SEND_PERSPECTIVES = 'SEND_PERSPECTIVES'
export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE'
export const SEND_AUDIO_MESSAGE = 'SEND_AUDIO_MESSAGE'
export const SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE'
export const SEND_SUBSCRIPTION = 'SEND_SUBSCRIPTION'

export const actions = {
  async [FETCH_PAGE_CONTENT]({ commit }, hash) {
    try {
      commit(SET_SEMINAR_HASH, hash)
      const response = await getPageContent(hash)
      commit(SET_PAGE_CONTENT, response.data)
    } catch (e) {
      console.error(e)
    }
  },
  async [SEND_IMGEMO]({ commit, getters }) {
    try {
      const selected = getters.selectedImages
      let data = {}
      selected.forEach(select => {
        const img = `img${select.galleryId}`
        const emo = `emo${select.galleryId}`
        data[img] = select.image.id
        data[emo] = select.emoticon
      })
      data.hash = getters.seminarHash
      const tokenName = getters.tokenName
      data[tokenName] = getters.token
      await sendImgemo({data})
      commit(CLEAR_SELECTED_IMAGES)
    } catch (e) {
      console.error(e)
    }
  },
  async [SEND_PERSPECTIVES]({ commit, getters }) {
    try {
      const ratings = getters.perspectivesRatings
      const data = {}
      ratings.forEach(r => {
        data[r.id] = r.points
      })
      data.hash = getters.seminarHash
      const tokenName = getters.tokenName
      data[tokenName] = getters.token
      await sendPerspectives({data})
      commit(CLEAR_RATINGS)
    } catch (e) {
      console.error(e)
    }
  },
  async [SEND_CONTACT_MESSAGE]({ commit, getters }, data) {
    try {
      const tokenName = getters.tokenName
      data[tokenName] = getters.token
      await sendContactMessage({data})
      commit(TOGGLE_MESSAGE_SCREEN, 'success')
    } catch (e) {
      commit(TOGGLE_MESSAGE_SCREEN, 'error')
    }
  },
  async [SEND_AUDIO_MESSAGE]({ commit, getters}, mp3) {
    try {
      const formData = new FormData()
      formData.append('audio', mp3)
      formData.append(getters.tokenName, getters.token)
      formData.append('hash', getters.seminarHash)
      await sendAudioMessage({data: formData})
      commit(TOGGLE_MESSAGE_SCREEN, 'success')
    } catch (e) {
      commit(TOGGLE_MESSAGE_SCREEN, 'error')
    }
  },
  async [SEND_TEXT_MESSAGE]({ commit, getters}, data) {
    try {
      data.hash = getters.seminarHash
      const tokenName = getters.tokenName
      data[tokenName] = getters.token
      await sendTextMessage({data})
      commit(TOGGLE_MESSAGE_SCREEN, 'success')
    } catch (e) {
      commit(TOGGLE_MESSAGE_SCREEN, 'error')
    }
  },
  async [SEND_SUBSCRIPTION]({ commit, getters }, data) {
    try {
      data.hash = getters.seminarHash
      const tokenName = getters.tokenName
      data[tokenName] = getters.token
      await subscribeEvent({data})
      commit(SET_SUBSCRIBED_EVENTS, data.event)
    } catch (e) {
      commit(SET_SUBSCRIBED_EVENTS, data.event)

      console.error(e)
    }
  }
}
