<template>
  <div class="imgemo-selected">
    <TeamInfo :image="imgemo.selected_image" :title="imgemo.selected_text" />
    <Navigation @prev="prevPage" @next="nextPage" title="Los geht’s" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import Navigation from '@/components/Navigation'
import {mapGetters} from 'vuex'
export default {
  name: 'ImgemoSelected',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['imgemo', 'galleries', 'selectedImages']),
  },
  methods: {
    prevPage() {
      this.$router.push({ name: 'imgemoGallery', params: { id: this.galleries[this.galleries.length - 1].id } })
    },
    nextPage() {
      this.$router.push({ name: 'selectEmoticon', params: { id: this.selectedImages[0].image.id } })
    }
  }
}
</script>

<style scoped>

</style>