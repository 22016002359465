<template>
  <div class="events-page page-content">
    <h1 v-html="events.events_text" class="page-title"></h1>
    <Flickity class="flickity" id="flickity" ref="flickity" :options="flickityOptions">
      <div class="event carousel-cell" v-for="(event, idx) in events.events" :key="event.id" :id="event.id" @click="activate(idx)">
        <div class="event-wrapper">
          <div v-if="!showText(event.id)" class="event-image">
            <img :src="src(event.image)" alt="img">
          </div>
          <div class="event-info">
            <div class="event-info-wrapper">
              <span class="event-type" v-html="event.eventtype"></span>
              <h3 class="event-title" v-html="event.title"></h3>
              <div class="date-time">
                <span class="event-place" v-html="event.place"></span>
                <span class="event-time">{{ formatDate(event.date) }}</span>
              </div>
              <p class="event-info-text" v-html="event.text" v-if="showText(event.id)"></p>
            </div>
            <button class="more-info" @click="toggle(event.id)">
              {{ showText(event.id) ? 'zurück' : 'mehr Infos' }}
            </button>
          </div>
          <div class="event-footer" @click="subscribe(event.id)" :class="disabled(event.id)" >
            <h3>Interesse anmelden</h3>
            <span>Ihre E-Mail-Adresse für eine Einladungs-Mail speichern</span>
          </div>
        </div>
      </div>
    </Flickity>
    <Navigation prev-page="eventsThank" next-page="social" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Flickity from 'vue-flickity'
import {assetSrc} from '@/helpers'
import moment from 'moment'
import {SEND_SUBSCRIPTION} from '@/store/actions'
import Navigation from '@/components/Navigation'

export default {
  name: 'EventsPage',
  components: {Navigation, Flickity},
  computed:{
    ...mapGetters(['events', 'isMobile', 'subscribedEvents'])
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        fullscreen: true,
        resize: false,
        fade: true,
        friction: 0.6,
      },
      infoText: false,
      mobile: this.isMobile
    }
  },
  methods: {
    disabled(id) {
      const event = this.subscribedEvents.find(event => event === id)
      return typeof event !== 'undefined' ? 'disabled' : ''
    },
    activate(idx) {
      this.$refs.flickity.select(idx, false, false)
    },
    src: assetSrc,
    toggle(id) {
      const isSelected = this.$refs.flickity.selectedElement().id === (id).toString()
      if (isSelected) {
        this.infoText = !this.infoText
      }
    },
    showText(id) {
      if (this.infoText) {
        const el = document.getElementById(id)
        if (el) {
          return el.classList.contains('is-selected')
        } else  {
          return false
        }
      } else {
        return false
      }
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY hh:mm')
    },
    subscribe(id) {
      const event = this.subscribedEvents.find(event => event === id)
      const isSelected = this.$refs.flickity.selectedElement().id === (id).toString()
      if (typeof event === 'undefined' && isSelected) {
        this.$store.dispatch(SEND_SUBSCRIPTION, { event: id })
      }
    }
  },
  created() {
    window.addEventListener('resize', () => {
      this.$refs.flickity?.resize()
    })

  },
  mounted() {
    this.$refs.flickity.on('dragStart', () => this.$refs.flickity.flickity().slider.style.pointerEvents = 'none')
    this.$refs.flickity.on('dragEnd', () => this.$refs.flickity.flickity().slider.style.pointerEvents = 'auto')
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.events-page {
  .flickity {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    @media (min-width: $tablet) {
      width: 70%;
    }
    @media (min-width: $desktop) {
      width: 60%;
    }
    @media (min-width: $desktopLarge) {
      width: 50%;
    }
    .flickity-viewport {
      height: 500px !important;
      .flickity-slider {
        display: flex;
        align-items: center;
      }
      .carousel-cell {
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        @media (min-width: $smartphone) {
          width: 45%;
        }
        @media (min-width: $desktop) {
          width: 35%;
        }
        @media (min-width: $desktopLarge) {
          width: 30%;
        }
        .event-wrapper {
          display: flex;
          flex-direction: column;
          width: 90%;
          min-height: 350px;
          transition: width 0.1s, height 0.1s;

          .event-image {
            display: flex;
            align-items: flex-start;
            img {
              height: auto;
              width: 100%;
              object-fit: cover;
            }
          }
          span {
            font-size: 10px;
          }
          .event-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px 24px;
            justify-content: space-around;
            background-color: $lightBlue;
            margin-bottom: 1px;
            .event-info-wrapper {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              background-color: $lightBlue;
              margin-bottom: 1px;
              .event-title {
                font-size: 16px;
                margin: 10px;
                font-weight: normal;
              }
              .date-time {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
            .more-info {
              border: 1px solid $darkBlue;
              border-radius: 3px;
              width: 76px;
              height: 24px;
              margin: 10px;
              background-color: $blueBtn;
              font-size: 10px;
            }
          }
          .event-footer {
            cursor: pointer;
            background-color: $textLightBlue;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 10px 20px 10px;
            text-align: center;
            &.disabled {
              opacity: 0.5;
              cursor: auto;
            }
            h3 {
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }
      .is-selected {
        opacity: 1;
        .event-wrapper {
          width: 100%;
          height: 450px;
          h3 {
            font-size: 20px;
          }
        }
      }
    }
    .event {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}


</style>