<template>
  <div class="contact-page">
    <div class="static-page">
      <span class="close-static" @click="closeStatic" v-if="lastRoute !== ''">
      <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>close</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gruppe_2" transform="translate(0.660000, 0.630000)" stroke="#000F7E">
            <line x1="19.09" y1="8.8817842e-16" x2="1.42108547e-14" y2="19.09" id="Linie_3"></line>
            <line x1="1.42108547e-14" y1="8.8817842e-16" x2="19.09" y2="19.09" id="Linie_4"></line>
        </g>
    </g>
  </svg>
    </span>
      <h1>Schreib der Villa</h1>
      <form class="contact-form">
        <input type="text" placeholder="Ihr Name" v-model="name">
        <input type="text" placeholder="Ihre Mailadresse (optional)" v-model="email">
        <textarea placeholder="Ihre Nachricht…" v-model="text" />
      </form>
    </div>
    <div class="send-btn" @click="sendMessage" :class="btnStyle">
      <span class="btn-title" >Senden</span>
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>arrow_right</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="arrow_right" fill="#000F7E" fill-rule="nonzero" points="0 19.64 19 10.14 0 0.64"></polygon>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import {SEND_CONTACT_MESSAGE} from '@/store/actions'
import {mapGetters} from 'vuex'
export default {
  name: 'Contact',
  data() {
    return {
      name: '',
      email: '',
      text: '',
    }
  },
  computed: {
    btnStyle() {
      return this.text.length === 0 || this.name.length === 0 ? 'disabled' : ''
    },
    ...mapGetters(['lastRoute'])
  },
  methods: {
    sendMessage() {
      if (this.btnStyle !== 'disabled') {
        const message = {
          name: this.name,
          email: this.email,
          text: this.text
        }
        this.$store.dispatch(SEND_CONTACT_MESSAGE, message)
        this.name = ''
        this.email = ''
        this.text = ''
      }
    },
    closeStatic() {
      this.$router.push(this.lastRoute)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.contact-page {
  .contact-form {
    display: flex;
    flex-direction: column;
    height: 70vh;
    textarea {
      flex: 1;
      resize: none;
    }
    input, textarea {
      background-color: white;
      color: $darkBlue;
      font-size: 20px;
      padding: 15px 9px;
      margin-bottom: 7px;
      border-radius: 4px;
      &::placeholder {
        color: $darkBlue;
        font-size: 20px;
      }
    }
  }
  .send-btn {
    cursor: pointer;
    position: absolute;
    bottom: 34px;
    right: 34px;
    font-size: 30px;
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
    .btn-title {
      margin-right: 12px;
    }
  }
}

</style>