<template>
  <div class="events-thank">
    <TeamInfo :image="events.thank_image" :title="events.thank_text" />
    <Navigation prev-page="sendMessage" next-page="eventsView" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'EventThank',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['events'])
  }
}
</script>

<style scoped>

</style>