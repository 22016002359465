<template>
  <div class="threep-intro page-content">
    <TeamInfo :title="threep.intro_text" :image="threep.intro_image" />
    <Navigation prev-page="threepStart" next-page="theepVideo" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'ThreepIntro',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['threep'])
  }
}
</script>

<style scoped>

</style>