<template>
  <div class="intro" :class="styleClass">
    <div class="content">
      <h1 v-html="introText"></h1>
      <img class="logo" src="/images/VtH.png" v-if="seminarStage === 'pre'" >
      <div class="img-wrapper" :class="seminarStage === 'pre' ? 'pre': 'post'">
        <Navigation :nextPage="nextPage" :show-prev="false" :title="btnText" />
        <img v-if="seminarStage === 'pre'" src="/images/VillaAlpha.png" alt="start_image">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {assetSrc} from '@/helpers'
import Navigation from '@/components/Navigation'
export default {
  name: 'PreIntro',
  components: {Navigation},
  computed: {
    ...mapGetters(['pre_intro', 'post_message', 'seminarStage']),
    introText() {
      return this.seminarStage === 'pre' ? this.pre_intro.start_text : 'Willkommen zurück! Wir haben eine Videobotschaft für Sie.'
    },
    btnText() {
      return this.seminarStage === 'pre' ? 'weiter' : 'abspielen'
    },
    styleClass() {
      return this.seminarStage === 'pre' ? 'pre' : 'post'
    },
    nextPage() {
      return this.seminarStage === 'pre' ? 'teamIntro' : 'message'
    }
  },
  methods: {
    src: assetSrc,
  },
  watch: {
    seminarStage (stage) {
      if (stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  &.pre {
    background: $lightBlue;
  }
  &.post {
    background: white;
  }
  .content {
    width: 100vw;
    h1 {
      margin-top: 20vh;
      width: 100%;
      font-size: 26px;
      font-weight: normal;
      text-align: left;
      padding: 0 30px;
      @media (min-width: $smartphone) {
        width: 75%;
        padding-left: 15%;
        font-size: 35px;
        margin-top: 10vh;
      }
      @media (min-width: $desktop) {
        width: 50%;
        padding-left: 15%;
        font-size: 40px;
        margin-top: 10vh;
      }
    }
    .logo {
      position: absolute;
      top: 0;
      right: 20px;
      width: 50%;
      @media (min-width: $tablet) {
        width: 25%;
      }
    }
    .img-wrapper {
      position: absolute;
      bottom: 0;
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .navigation {
        bottom: 100%;
        position: absolute;
      }
      @media (min-width: $tablet) {
        .navigation {
          bottom: 0;
        }
      }
      &.pre {
        animation-name: showImg;
        animation-duration: 3s;
      }
      img {
        max-width: 100%;
        max-height: 50vh;
        @media (min-width: $tablet) {
          max-width: 70%;
        }
      }
    }
  }
}
@keyframes showImg {
  0%   {bottom: -100%; opacity: 0}
  100% {bottom: 0; opacity: 1}
}

</style>
