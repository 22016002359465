<template>
  <div class="threep-intro-video page-content">
    <VideoPlayer
        :video="video"
        :poster="videoPoster"
        autoplay
        :controls="false"
        @ended="ended=true"
    />
    <p class="subtitle" v-html="threep.introvideo_subtitle"></p>
    <Navigation prev-page="threepIntro" next-page="theepQuestion" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VideoPlayer from '@/components/VideoPlayer'
import Navigation from '@/components/Navigation'

export default {
  name: 'ThreepIntroVideo',
  components: {Navigation, VideoPlayer},
  data() {
    return {
      ended: false
    }
  },
  computed: {
    ...mapGetters(['threep']),
    video() {
      return this.isMobile ? this.threep.introvideo_mobile : this.threep.introvideo
    },
    videoPoster() {
      return this.isMobile ? this.threep.introvideo_mobile_poster : this.threep.introvideo_poster
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.threep-intro-video {
  .subtitle {
    margin-top: 15px;
    width: 80%;
    @media (min-width: $tablet) {
      width: 40%;
    }
  }
}

</style>