<template>
  <div class="pre-end page-content">
    <TeamInfo :title="pre_end.end_text" :image="pre_end.end_image" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
export default {
  name: 'PreEnd',
  components: {TeamInfo},
  computed: {
    ...mapGetters(['pre_end', 'seminarStage'])
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'pre' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style scoped>

</style>