<template>
  <div class="video-player">
    <div class="video-wrapper">
      <video
          ref="video"
          @timeupdate="timeUpdate"
          @ended="ended"
          playsinline
          :src="src(video)"
          type="video/mp4"
          :poster="src(poster)"
          @play="playing = true"
          @pause="playing = false"
          :id="videoId"
      ></video>
      <button class="close-video" @click="closeVideo" v-if="controls">
        <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>close</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Gruppe_2" transform="translate(0.660000, 0.630000)" stroke="#000F7E">
              <line x1="19.09" y1="8.8817842e-16" x2="1.42108547e-14" y2="19.09" id="Linie_3"></line>
              <line x1="1.42108547e-14" y1="8.8817842e-16" x2="19.09" y2="19.09" id="Linie_4"></line>
            </g>
          </g>
        </svg>
      </button>
      <div class="video-controls" v-if="controls">
        <PlayerControls
            :time="time"
            :duration="duration"
            v-model="playing"
            @seek="seek"
        />
      </div>
    </div>
    <div class="subtitle">
      <div class="subtitle-text">
        <p v-html="currentSub.text"></p>
      </div>
      <div class="subtitle-name">
        <span v-html="currentSub.name"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {assetSrc} from '@/helpers'
import PlayerControls from '@/components/PlayerControls'

export default {
  name: 'VideoPlayer',
  components: {PlayerControls},
  props: {
    video: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    subtitles: {
      type: Array,
      default: function () {
        return []
      }
    },
    controls: {
      type: Boolean,
      default: true
    },
    videoId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      title: '',
      time: 0,
      duration: 0,
      playing: false,
      subs: [],
      currentSub: {},
    }
  },
  computed: {
    currentTime() {
      return Math.floor(this.time / 100 * this.duration)
    },
  },
  methods: {
    timeUpdate() {
      if (typeof this.$refs.video !== 'undefined') {
        this.time = this.$refs.video.currentTime / this.duration * 100
      }
    },
    closeVideo() {
      this.$emit('close')
    },
    src: assetSrc,
    checkAudioState() {
      let checkAudioState
      if (typeof this.$refs.video !== 'undefined') {
        checkAudioState = setInterval(() => {
          if (typeof this.$refs.video !== 'undefined' && this.$refs.video.readyState === 4) {
            this.duration = this.$refs.video.duration
            clearInterval(checkAudioState)
          }
        }, 10)
      } else {
        clearInterval(checkAudioState)
      }
    },
    play() {
      this.$refs.video.play()
    },
    seek(pos) {
      this.$refs.video.currentTime = pos / 100 * this.duration
    },
    ended() {
      this.playing = true
      this.$emit('ended', true)
    }
  },
  mounted() {
    this.checkAudioState()

    if (this.subtitles.length > 0) {
      let subs = []
      this.subtitles.forEach((sub, index) => {
        sub.start = sub.time
        if (index === 0) {
          sub.start = 0
        } else {
          sub.start = sub.time
        }
        if (index !== 0) {
          subs[index - 1].end = sub.time
        }
        if (this.subtitles.length-1  === index) {
          sub.end = 0
        }
        sub.text = `»${sub.text}«`
        subs.push(sub)
      })
      this.subs = subs
    }

    // start autoplay if required
    setTimeout(() => {
      if (this.autoplay) {
        this.play()
      }
    }, 500)
  },
  watch: {
    time: function (val) {
      this.timeSlider = isNaN(val) ? 0 : val
      if (this.subtitles.length > 0) {
        const sub = this.subs.find(subtitle => subtitle.start <= this.currentTime && subtitle.end > this.currentTime || subtitle.end === 0)
        if (typeof sub !== 'undefined') {
          this.currentSub = sub
        }
      }
    },
  }
}
</script>

<style  lang="scss">
@import "src/styles/app";
.video-player {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  .close-video {
    background: transparent;
    border: none;
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .video-wrapper {
    position: relative;
  }
  .video-controls {
    width: 100%;
  }
  video {
    max-width: 100%;
    max-height: 80vh;
    @media (min-height: $tablet) {
      max-height: 65vh;
    }

  }
  .controls {
    display: block;
    width: 100%;
    bottom: -3px;
    position: absolute;
    .time {
      display: none;
    }
    .play-pause-button {
      display: none;
    }
    .full-screen {
      display: none;
    }
    .slider,
    .vue-slider,
    .vue-slider-ltr {
      width: 100% !important;
      height: 3px !important;
    }
    .vue-slider-rail {
      background-color: transparent !important;
    }
    .vue-slider-process {
      background-color: $darkBlue !important;
    }
    .vue-slider-dot {
      display: none;
    }
    .player-controls {
      .controls {
        flex-direction: column-reverse;
        align-items: flex-start;
        .replay {
          margin-left: 13px;
          margin-top: 5px;
          svg {
            path {
              fill: $gray;
            }
          }
        }
      }
    }
  }
  .subtitle {
    width: 90%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin: 29px 0;

    @media (min-width: $tablet) {
      width: 70%;
    }
    .subtitle-text {
      display: flex;
      justify-content: flex-start;
      width: 80%;
      line-height: 29px;
      position: relative;
      @media (min-width: $tablet) {
        width: 60%;
      }
    }
    .subtitle-name {
      display: flex;
      justify-content: center;
      font-style: italic;
    }
  }
}

</style>
