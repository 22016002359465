<template>
  <div class="audio-recorder">
    <div class="recorder-content">
      <span class="timer" v-html="formatTime"></span>
      <button class="record" @click="recordAudio" :class="micStyle">
        <svg width="179px" height="179px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>mic</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="mic" transform="translate(0.500000, 0.640000)" fill-rule="nonzero">
              <circle id="Oval" fill="#000F7E" cx="12" cy="12" r="12"></circle>
              <rect id="Rectangle" fill="#FFFFFF" x="9" y="5" width="6" height="11" rx="3"></rect>
              <path d="M12,18.5 C9.46729464,18.4945001 7.41549992,16.4427054 7.41,13.91 L7.41,11.09 L8.41,11.09 L8.41,13.91 C8.41,15.892702 10.017298,17.4999994 12,17.4999994 C13.982702,17.4999994 15.5899997,15.892702 15.59,13.91 L15.59,11.09 L16.59,11.09 L16.59,13.91 C16.5845001,16.4427054 14.5327054,18.4945001 12,18.5 Z" id="Path" fill="#FFFFFF"></path>
              <rect id="Rectangle" fill="#FFFFFF" x="8" y="19" width="8" height="2"></rect>
              <rect id="Rectangle" fill="#000F7E" x="9" y="8" width="2" height="1"></rect>
              <rect id="Rectangle" fill="#000F7E" x="9" y="10" width="2" height="1"></rect>
              <rect id="Rectangle" fill="#000F7E" x="9" y="12" width="2" height="1"></rect>
              <rect id="Rectangle" fill="#000F7E" x="13" y="8" width="2" height="1"></rect>
              <rect id="Rectangle" fill="#000F7E" x="13" y="10" width="2" height="1"></rect>
              <rect id="Rectangle" fill="#000F7E" x="13" y="12" width="2" height="1"></rect>
            </g>
          </g>
        </svg>
      </button>
      <p class="recorder-info" v-html="text"></p>
      <p class="recorder-info">Die Sprachnachricht wird nach der Aufnahme automatisch versandt.</p>
    </div>
    <div class="back" @click="closeAudio">
      <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>arrow_left</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="arrow_left" fill="#000F7E" fill-rule="nonzero" points="19 19.64 0 10.14 19 0.64"></polygon>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioRecorder',
  computed: {
    micStyle() {
      return this.recording ? 'recording' : ''
    },
    text() {
      return  this.recording ? 'Aufnahme läuft…' : 'drücken und sprechen'
    },
    formatTime() {
      return this.maxTime < 10 ? `0${this.time}` : `${this.time}`
    }
  },
  props: {
    maxTime: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      recording: false,
      recorder: null,
      audioChunks: [],
      audioBlob: null,
      audioFile: null,
      audioUrl: null,
      file: null,
      audio: null,
      time: `${this.maxTime}:00`,
      timeSeconds: this.maxTime * 60
    }
  },
  methods: {
    recordAudio() {
      if (!this.recording) {
        this.recorder.start()
        this.recording = true
        this.startTimer()
      } else {
        this.stopRecording()
      }
    },
    stopRecording() {
      if (this.maxTime * 60 - this.timeSeconds > 2) {
        this.recorder.stop()
        this.recording = false
      }
    },
    closeAudio() {
      this.$emit('closeAudio')
    },
    startTimer() {
      setInterval(this.updateTimer, 1000)
    },
    updateTimer() {
      this.timeSeconds--
      let time = this.timeSeconds
      let minutes = Math.floor(time / 60)
      let seconds = time % 60
      seconds = seconds < 10 ? `0${seconds}` : seconds
      this.time = `${minutes}:${seconds}`
    }
  },
  mounted() {
    let device = navigator.mediaDevices.getUserMedia({ audio: true })
    device.then((stream) => {
      this.recorder = new MediaRecorder(stream)
      this.recorder.addEventListener('dataavailable', event => {
        this.audioChunks.push(event.data)
      })
      this.recorder.addEventListener('stop', () => {
        this.audioBlob = new Blob(this.audioChunks)
        this.audioFile = new File([this.audioBlob], 'audio-file')
        this.$emit('stopAudio', this.audioFile)
      })
    })
  },
  watch: {
    timeSeconds(seconds) {
      if (seconds === 0) {
        this.stopRecording()
      }
    }
  }
}
</script>

<style  lang="scss">
@import "src/styles/app";
.audio-recorder {
  height: 100vh;
  width: 100vw;
  background-color: $lightBlue;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .recorder-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 43px;
    .timer {
      margin-bottom: 25px;
    }
    .record {
      border-radius: 100%;
      &.recording {
        box-shadow: 0 0 20px 2px rgb(255 0 0 / 1);
      }
    }
    svg {
      margin: -7px;
    }
    .recorder-info {
      font-size: 20px;
      margin-top: 40px;
    }
  }
  .back {
    position: absolute;
    bottom: 20px;
    left: 30px;
    cursor: pointer;
  }
}

</style>