<template>
  <div class="player-controls">
    <div class="controls">
      <button class="replay" @click="replay">
        <svg width="24px" height="27px" viewBox="0 0 24 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>restart</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="restart" transform="translate(1.000000, 0.000000)">
              <path d="M11,3.14 C17.0751322,3.14 22,8.06486775 22,14.14 C22,20.2151322 17.0751322,25.14 11,25.14 C4.92486775,25.14 0,20.2151322 0,14.14" id="Path" stroke="#000F7E" stroke-width="2"></path>
              <polygon id="Path" fill="#000F7E" fill-rule="nonzero" points="7 3.14 12.5 0 12.5 6.29"></polygon>
            </g>
          </g>
        </svg>
      </button>
      <vue-slider
          class="seek-range"
          v-model="timeSlider"
          dot-size="13"
          tooltip="none"
          @change="seek"
      ></vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  components: {VueSlider},
  name: 'PlayerControls',
  data() {
    return {
      timeSlider: this.time,
      ended: false
    }
  },
  props: {
    time: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0
    }
  },
  methods: {
    seek (pos) {
      this.$emit('seek', pos)
    },
    replay () {
      this.$emit('seek', 0)
      this.$emit('play')
    },
  },
  watch: {
    time: function (val) {
      this.timeSlider = isNaN(val) ? 0 : val
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/app";
.player-controls {
  background: transparent;
  position: relative;
  cursor: pointer;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .replay {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 24px;
    height: 27px;
  }
  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .slider,
    .vue-slider,
    .vue-slider-ltr {
      width: 100% !important;
    }
    .vue-slider:hover {
      .vue-slider-process {
        background: $darkBlue;
      }
    }
    .vue-slider-process {
      background: white;
      &:hover {
        background: $darkBlue !important;
      }
    }
    .vue-slider-rail {
      background-color: white !important;
    }

    .vue-slider-dot {
      .vue-slider-dot-handle {
        border: none !important;
        z-index: -1 !important;
        overflow: hidden !important;
      }
    }
  }
}

</style>
