<template>
  <div class="send-message team-info">
    <div class="team-info-top">
      <h1 class="page-title">
        Wir haben Ihnen von unserem „Villa“-Moment erzählt.
        Sicher ist Ihnen auch ein besonderer Moment im Kopf geblieben.
        Welcher Moment beschäftigt Sie über das Seminarende hinaus und warum?
      </h1>
    </div>
    <div class="team-info-bottom">
      <div class="message-form">
        <textarea placeholder="Ihre Aussage…" v-model="text" />
        <div class="buttons">
          <button @click="audio = true">
            <svg width="48px" height="48px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>mic</title>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="mic" transform="translate(0.500000, 0.640000)" fill-rule="nonzero">
                  <circle id="Oval" fill="#000F7E" cx="12" cy="12" r="12"></circle>
                  <rect id="Rectangle" fill="#FFFFFF" x="9" y="5" width="6" height="11" rx="3"></rect>
                  <path d="M12,18.5 C9.46729464,18.4945001 7.41549992,16.4427054 7.41,13.91 L7.41,11.09 L8.41,11.09 L8.41,13.91 C8.41,15.892702 10.017298,17.4999994 12,17.4999994 C13.982702,17.4999994 15.5899997,15.892702 15.59,13.91 L15.59,11.09 L16.59,11.09 L16.59,13.91 C16.5845001,16.4427054 14.5327054,18.4945001 12,18.5 Z" id="Path" fill="#FFFFFF"></path>
                  <rect id="Rectangle" fill="#FFFFFF" x="8" y="19" width="8" height="2"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="9" y="8" width="2" height="1"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="9" y="10" width="2" height="1"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="9" y="12" width="2" height="1"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="13" y="8" width="2" height="1"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="13" y="10" width="2" height="1"></rect>
                  <rect id="Rectangle" fill="#000F7E" x="13" y="12" width="2" height="1"></rect>
                </g>
              </g>
            </svg>
          </button>
          <div class="send-btn" @click="sendText" :class="btnStyle">
            <span class="btn-title" >Senden</span>
            <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>arrow_right</title>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon id="arrow_right" fill="#000F7E" fill-rule="nonzero" points="0 19.64 19 10.14 0 0.64"></polygon>
              </g>
            </svg>
          </div>
        </div>
        </div>
    </div>
    <AudioRecorder v-if="audio" @closeAudio="audio = false" @stopAudio="sendAudio" :max-time="5" />
    <Transition name="fade" mode="out-in">
    </Transition>
    <Navigation prev-page="message" next-page="eventsThank" title="überspringen" />
  </div>
</template>

<script>
import {SEND_AUDIO_MESSAGE, SEND_TEXT_MESSAGE} from '@/store/actions'
import AudioRecorder from '@/components/AudioRecorder'
import Navigation from '@/components/Navigation'
import {mapGetters} from 'vuex'

export default {
  name: 'SendMessage',
  components: {Navigation, AudioRecorder},
  data() {
    return {
      text: '',
      audio: false,
    }
  },
  computed: {
    btnStyle() {
      return this.text.length === 0 ? 'disabled' : ''
    },
    ...mapGetters(['seminarStage'])
  },
  methods: {
    sendText() {
      if (this.text.length > 0 && this.btnStyle !== 'disabled') {
        this.$store.dispatch(SEND_TEXT_MESSAGE, { text: this.text })
        this.redirect()
      }
    },
    sendAudio(audioFile) {
      this.audio = false
      this.$store.dispatch(SEND_AUDIO_MESSAGE, audioFile)
      this.redirect()
    },
    redirect() {
      this.$router.push({ name: 'eventsThank' })
    }
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'post' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.send-message {
  .team-info-bottom {
    justify-content: center;
    .message-form {
      height: 80%;
      width: 100%;
      padding: 20px;
      @media (min-width: $tablet) {
        width: 60%;
      }
      @media (min-width: $desktop) {
        width: 40%;
      }
      textarea {
        height: 60%;
        width: 100%;
        background: white;
        padding: 16px 9px;
        font-size: 20px;
        color: $darkBlue;
        border-radius: 8px;
        resize: none;
        margin-bottom: 18px;
        &::placeholder {
          color: $darkBlue;
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .send-btn {
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
            cursor: default;
          }
          .btn-title {
            margin-right: 10px;
            display: inline;
            font-size: 34px;
          }
        }
      }
    }
  }
  .navigation {
    .navigation-item-title {
      font-size: 26px;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
}

</style>