<template>
  <div class="imgemo-gallery">
    <div class="gallery-wrapper">
      <h1 v-html="currentGallery.title" class="page-title"></h1>
      <flickity class="flickity" ref="flickity" :options="flickityOptions">
        <div class="carousel-cell" :class="selectedClass(image.id)" v-for="(image, idx) in images" :key="idx">
          <img class="carousel-cell-image" :data-flickity-lazyload="src(image.image)" alt="image"/>
          <button class="fullscreen-btn" @click="openDialog(idx)">
            <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>expand</title>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="expand" transform="translate(0.500000, 0.640000)" fill="#000F7E" fill-rule="nonzero" stroke="#000F7E" stroke-width="0.25">
                  <polygon id="Path" points="1 1.71 3.79 4.5 4.5 4.5 4.5 3.8 1.71 1 4.5 1 4.5 0 0 0 0 4.5 1 4.5"></polygon>
                  <polygon id="Path" points="1.71 19 4.5 16.21 4.5 15.5 3.79 15.5 1 18.3 1 15.5 0 15.5 0 20 4.5 20 4.5 19"></polygon>
                  <polygon id="Path" points="19 18.3 16.21 15.5 15.5 15.5 15.5 16.21 18.29 19 15.5 19 15.5 20 20 20 20 15.5 19 15.5"></polygon>
                  <polygon id="Path" points="18.29 1 15.5 3.8 15.5 4.5 16.21 4.5 19 1.71 19 4.5 20 4.5 20 0 15.5 0 15.5 1"></polygon>
                </g>
              </g>
            </svg>
          </button>
          <div class="btn-subtitle">
            <button class="select-btn" @click="selectImage(image)">auswählen</button>
            <span class="image-subtitle" v-html="image.subtitle"></span>
          </div>
        </div>
      </flickity>
    </div>
    <div class="gallery-question">
      <p class="question page-title" v-html="currentGallery.question"></p>
    </div>
    <div data-app>
      <ImageDialog v-model="showDialog" :image="currImage" @close="closeDialog" />
    </div>
    <Navigation :disabled-next="!selectedImage && !selected" @prev="prevPage" @next="nextPage" />
  </div>
</template>

<script>
import Flickity from 'vue-flickity'
import {mapGetters} from 'vuex'
import {assetSrc} from '@/helpers'
import ImageDialog from '@/components/ImageDialog'
import Navigation from '@/components/Navigation'
import {SELECT_IMAGE} from '@/store/mutations'
export default {
  components: {Navigation, ImageDialog, Flickity},
  name: 'ImgemoGallery',
  computed: {
    ...mapGetters(['currentGallery', 'team', 'nextGallery', 'prevGallery', 'selectedImages']),
    images() {
      return this.currentGallery.images
    },
    selectedImage() {
      const image = this.selectedImages.find(image => image.galleryId === this.currentGallery.id)
      return typeof image !== 'undefined' ? image.image : null
    }
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        fullscreen: true,
        lazyLoad: 4,
        wrapAround: true
      },
      showDialog: false,
      currImage: this.images,
      selected: this.selectedImage
    }
  },
  methods: {
    src: assetSrc,
    openDialog(idx) {
      this.currImage = this.images[idx]
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    selectImage(image) {
      this.selected = image
    },
    selectedClass(id) {
      const selected = this.selectedImages.find(image => image.image.id === id)
      if ((typeof selected !== 'undefined' && !this.selected) || (this.selected && this.selected.id === id)) {
        return 'selected'
      } else {
        return ''
      }
    },
    prevPage() {
      if (typeof this.prevGallery !== 'undefined') {
        this.$router.push({ name: 'imgemoGallery', params: { id: this.prevGallery.id } })
        window.location.reload()
      } else {
        this.$router.push({ name: 'imgemoStart' })
      }
    },
    nextPage() {
      if (this.selectedImage || this.selected) {
        const image = typeof this.selected !== 'undefined' ? this.selected : this.selectedImage
        this.$store.commit(SELECT_IMAGE, { gallery: this.currentGallery, image })
        if (typeof this.nextGallery !== 'undefined') {
          this.$router.push({ name: 'imgemoGallery', params: { id: this.nextGallery.id } })
          window.location.reload()
        } else {
          this.$router.push({ name: 'imgemoSelected' })
        }
      }
    },
  },
  mounted() {
    this.$refs.flickity.on('dragStart', () => this.$refs.flickity.flickity().slider.style.pointerEvents = 'none')
    this.$refs.flickity.on('dragEnd', () => this.$refs.flickity.flickity().slider.style.pointerEvents = 'auto')
  }
}
</script>

<style  lang="scss">
@import "src/styles/app";
.imgemo-gallery {
  display: flex;
  flex-direction: column;
  .gallery-wrapper {
    text-align: center;
    .page-title {
      text-align: center;
      padding: 0;
      max-width: 100%;
    }
    .gallery-title {
      margin-top: 40px;
    }
    .flickity {
      margin-top: 20px;
      margin-bottom: 30px;
      .selected {
        img {
          border: 3px solid $darkBlue;
        }
        .select-btn {
          background: $darkBlue !important;
          color: white;
          opacity: 1;
        }
      }
    }
    .fullscreen-btn {
      background: transparent;
      border: none;
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .btn-subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;

      .select-btn {
        display: inline-block;
        margin-bottom: .5rem;
        margin-top: -2rem;
        background-color: $lightBlue;
        opacity: 0.6;
        padding: 4px 10px;
        border-radius: 5px;
      }
      .image-subtitle {
        font-size: 14px;
      }
      .image-subtitle {
        font-size: 14px;
        width: 100%;
      }
    }


  }
  .gallery-question {
    display: flex;
    justify-content: center;
    .question {
      width: 100%;
      @media (min-width: $tablet) {
        width: 40%;
      }
    }
  }
}
</style>
