<template>
  <div class="imgemo-start">
    <TeamInfo :title="imgemo.start_text" :image="imgemo.start_image" />
    <Navigation prevPage="teamEnd" @next="nextPage" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'ImgemoStart',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['imgemo', 'galleries']),
  },
  methods: {
    nextPage() {
      this.$router.push({ name: 'imgemoGallery', params: { id: this.galleries[0].id } })
    }
  }
}
</script>

<style scoped>

</style>