import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {VImg} from 'vuetify/lib/components'
import {VDialog} from 'vuetify/lib/components'

Vue.use(Vuetify, {
  components: {
    VImg,
    VDialog
  }
})

export default new Vuetify({
})
