import {slugify} from '@/helpers'

export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const HIDE_MENU = 'HIDE_MENU'
export const SET_MOBILE = 'SET_MOBILE'
export const SET_DESKTOP = 'SET_DESKTOP'
export const SET_GALLERIES = 'SET_GALLERIES'
export const SELECT_IMAGE = 'SELECT_IMAGE'
export const SELECT_EMOTICON = 'SELECT_EMOTICON'
export const CLEAR_SELECTED_IMAGES = 'CLEAR_SELECTED_IMAGES'
export const SET_TRANSITIONS = 'SET_PERSPECTIVES'
export const SET_RATINGS = 'SET_RATINGS'
export const CLEAR_RATINGS = 'CLEAR_RATINGS'
export const SET_STATIC_PAGES = 'SET_STATIC_PAGES'
export const SET_SUBSCRIBED_EVENTS = 'SET_SUBSCRIBED_EVENTS'
export const TOGGLE_MESSAGE_SCREEN = 'TOGGLE_MESSAGE_SCREEN'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
export const RESET_LAST_ROUTE = 'RESET_LAST_ROUTE'
export const RESET_MESSAGE_SCREEN = 'RESET_MESSAGE_SCREEN'
export const RESET_STAGE = 'RESET_STAGE'
export const SET_SEMINAR_HASH = 'SET_SEMINAR_HASH'

export const mutations = {
  [SET_SEMINAR_HASH](state, hash) {
    state.seminarHash = hash
  },
  [SET_PAGE_CONTENT](state, page) {
    state.page = page.data
    state.seminarId = page.seminarId
    state.seminarStage = page.seminarStage
    state.participantId = page.participantId
    state.token = page.token
    state.tokenName = page['token-name']
    state.notFound.text = page.data['notfound']?.text || ''
    state.social = page.data.social

    this.commit(SET_STATIC_PAGES, [page.data['imprint'], page.data['privacy']])

    if (page.seminarStage === 'pre') {
      state.team = page.data.team
      state.pre_intro = page.data.pre_intro
      state.imgemo = page.data.imgemo
      state.threep = page.data.threep
      state.pre_end = page.data.pre_end
      this.commit(SET_GALLERIES, page.data.imgemo.imagecategories)
      this.commit(SET_TRANSITIONS, page.data.threep)
    } else if (page.seminarStage === 'post') {
      state.post_message = page.data.post_message
      state.events = page.data.events
    }
  },
  [TOGGLE_MENU] (state) {
    state.showMenu = !state.showMenu
  },
  [HIDE_MENU] (state) {
    state.showMenu = false
  },
  [SET_MOBILE](state) {
    state.isMobile = true
  },
  [SET_DESKTOP](state) {
    state.isMobile = false
  },
  [SET_GALLERIES](state, categories) {
    if (categories.length > 0) {
      let galleries = []
      categories.forEach((category, idx) => {
        if (category.title.length > 0) {
          category.id = idx + 1
          galleries.push(category)
        }
      })
      state.galleries = galleries
    } else {
      state.galleries = []
    }
  },
  [SELECT_IMAGE](state, {gallery, image}) {
    let selected = { galleryId: gallery.id, image: image, emoticon: 0 }
    let selectedIdx = state.selectedImages.findIndex(img => img.galleryId === gallery.id)
    if (selectedIdx > -1) {
      state.selectedImages[selectedIdx] = selected
    } else {
      state.selectedImages.push(selected)
    }
  },
  [SELECT_EMOTICON](state, {imageId, emoId}) {
    const imageIdx = state.selectedImages.findIndex(image => image.image.id === imageId)
    state.selectedImages[imageIdx].emoticon = emoId
  },
  [CLEAR_SELECTED_IMAGES](state) {
    state.selectedImages = []
  },
  [SET_TRANSITIONS](state, threep) {
    state.transitions = [
      {
        video: threep.transition12video,
        video_poster: threep.transition12video_poster,
        video_mobile: threep.transition12video_mobile,
        video_mobile_poster: threep.transition12video_mobile_poster,
      },
      {
        video: threep.transition23video,
        video_poster: threep.transition23video_poster,
        video_mobile: threep.transition23video_mobile,
        video_mobile_poster: threep.transition23video_mobile_poster,
      },
      {
        video_poster: threep.perspective3_poster,
        video_mobile_poster: threep.perspective3_mobile_poster,
      },
    ]
  },
  [SET_RATINGS](state, ratings) {
    state.perspectivesRatings = ratings
  },
  [CLEAR_RATINGS](state) {
    state.perspectivesRatings = [
      {points: 50, id: 'p1v1'},
      {points: 50, id: 'p1v2'},
      {points: 50, id: 'p2v1'},
      {points: 50, id: 'p2v2'},
      {points: 50, id: 'p3v1'},
      {points: 50, id: 'p3v2'},
    ]
  },
  [SET_STATIC_PAGES](state, pages) {
    pages.forEach(page => {
      page.slug = slugify(page.title)
    })
    state.staticPages = pages
  },
  [SET_SUBSCRIBED_EVENTS](state, eventId) {
    state.subscribedEvents.push(eventId)
  },
  [TOGGLE_MESSAGE_SCREEN](state, type = 'success') {
    state.messageScreenType = type
    state.messageScreenShow = !state.messageScreenShow
  },
  [SET_LAST_ROUTE](state, route) {
    state.lastRoute = route
  },
  [RESET_LAST_ROUTE](state) {
    state.lastRoute = ''
  },
  [RESET_MESSAGE_SCREEN](state) {
    state.messageScreenShow = false
  },
  [RESET_STAGE](state) {
    state.seminarStage = ''
  }
}
