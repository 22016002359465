<template>
  <div class="team-end">
    <TeamInfo :title="team.end_text" :image="team.end_image" />
    <Navigation prevPage="teamVideo" nextPage="imgemoStart" />
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'TeamEnd',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['team'])
  }
}
</script>

<style scoped>

</style>