<template>
  <div class="team-info">
    <div class="team-info-top">
      <h1 class="page-title" v-html="title"></h1>
    </div>
    <div class="team-info-bottom">
      <div class="team-info-image">
        <img :src="src(image)" alt="image">
      </div>
    </div>
  </div>
</template>

<script>
import {assetSrc} from '@/helpers'

export default {
  name: 'TeamInfo',
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  methods: {
    src: assetSrc
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.team-info {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  .team-info-top {
    height: 40vh;
    display: flex;
    justify-content: center;
    .page-title {
      z-index: 3;
    }
  }

  .team-info-bottom {
    background-color: $lightBlue;
    width: 100vw;
    height: 60vh;
    display: flex;
    position: relative;

    .team-info-image {
      position: absolute;
      height: 67vh;
      display: flex;
      bottom: 0;
      left: 0;
      justify-content: flex-start;
      width: 100%;
      //max-width: 555px;
      max-width: 70vmin;

      @media(min-width: 600px) {
        max-width: 60vmin;
      }

      @media (min-width: $tablet) {
        left: 20%;
        max-width: 50vmin;
      }

      &:before {
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg width="139" height="66" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M0 66L32 0h107z" fill-rule="evenodd"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        width: 11vh;
        height: 6vh;
        max-height: 66px;
        display: block;
        top: 5vh;
        left: 50%;
        margin-left: 8vh;
        position: absolute;
        z-index: 3;
      }

      img {
        position: relative;
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
  }

}

</style>
