<template>
  <div class="not-found">
    <div>
      <h1 v-text="notFound.text"></h1>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'NotFound',
  computed: {
    ...mapGetters(['notFound'])
  },
}
</script>

<style lang="scss">
.not-found {
  min-height: calc(100vh - 62px);
  justify-content: space-around;
  flex-direction: column;
  display: flex;
}
h1 {
  font-size: 2rem;
  margin-bottom: .5rem;
}
h1, h3 {
  text-align: center;
}
</style>
