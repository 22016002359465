export const getters = {
  page: state => state.page,
  showMenu: state => state.showMenu,
  isMobile: state => state.isMobile,
  team: state => state.team,
  pre_intro: state => state.pre_intro,
  imgemo: state => state.imgemo,
  threep: state => state.threep,
  galleries: state => state.galleries,
  currentGallery: state => {
    return state.galleries.find(gallery => gallery.id === parseInt(state.route.params.id, 10))
  },
  nextGallery: state => {
    const currGalleryIdx = state.galleries.findIndex(gallery => gallery.id === parseInt(state.route.params.id, 10))
    return state.galleries[currGalleryIdx + 1]
  },
  prevGallery: state => {
    const currGalleryIdx = state.galleries.findIndex(gallery => gallery.id === parseInt(state.route.params.id, 10))
    return state.galleries[currGalleryIdx - 1]
  },
  selectedImages: state => state.selectedImages,
  currentImage: state => {
    return state.selectedImages.find(img => parseInt(img.image.id, 10) === parseInt(state.route.params.id, 10))
  },
  nextImage: state => {
    const currImageIdx = state.selectedImages.findIndex(img => parseInt(img.image.id, 10) === parseInt(state.route.params.id, 10))
    return state.selectedImages[currImageIdx + 1]
  },
  prevImage: state => {
    const currImageIdx = state.selectedImages.findIndex(img => parseInt(img.image.id, 10) === parseInt(state.route.params.id, 10))
    return state.selectedImages[currImageIdx - 1]
  },
  tokenName: state => state.tokenName,
  token: state => state.token,
  transitions: state => state.transitions,
  perspectivesRatings: state => state.perspectivesRatings,
  pre_end: state => state.pre_end,
  currentStaticPage: state => {
    const pageIdx = state.staticPages.findIndex(page => page.slug === state.route.params.slug)
    if (state.route.name === 'staticPage' && pageIdx > -1) {
      return state.staticPages[pageIdx]
    }
    return null
  },
  staticPages: state => state.staticPages,
  post_message: state => state.post_message,
  seminarStage: state => state.seminarStage,
  seminarHash: state => state.seminarHash,
  events: state => state.events,
  notFound: state => state.notFound,
  social: state => state.social,
  subscribedEvents: state => state.subscribedEvents,
  messageScreenShow: state => state.messageScreenShow,
  messageScreenType: state => state.messageScreenType,
  lastRoute: state => state.lastRoute
}
