<template>
  <div class="threep-qustion page-content">
    <TeamInfo :title="threep.qustion_text" :image="threep.qustion_image" />
    <Navigation prev-page="theepVideo" @next="nextPage" title="Los geht’s" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'

export default {
  name: 'ThreepQuestion',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['threep'])
  },
  methods: {
    nextPage() {
      this.$router.push({ name: 'perspectives', params: { id: (1).toString() } })
    }
  }
}
</script>

<style scoped>

</style>