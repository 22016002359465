<template>
  <div class="team-intro page-content">
    <h1 class="page-title">Unser Seminar mit Ihnen besteht aus drei Schritten:</h1>
    <div class="intro-images">
      <div class="intro-image" v-for="(image, index) in images" :key="index" >
        <transition name="fade" mode="out-in">
          <div class="image-wrapper" v-if="image.show">
            <img :src="image.src" alt="img" >
            <span class="image-title" v-html="image.title"></span>
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade" mode="out-in">
    <div class="info-wrapper" v-if="info.show">
      <div class="arrow">
        <svg width="45" height="83"><path fill="#000F7E" class="cls-1" d="M23.47,68.81C10.5,56,5.2,37,8,13.9l4.78.93L9.69,2,2,12.73l4.06.79C3.89,31.18,5.49,53.86,22.13,70.3A53.9,53.9,0,0,0,45.55,84.06L46,82.12A53.07,53.07,0,0,1,23.47,68.81Z"/></svg>
      </div>
      <div class="info-text" v-html="info.text"></div>
    </div>

    </transition>
    <Navigation nextPage="teamStart" prevPage="preIntro" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import {mapGetters} from 'vuex'

export default {
  components: {Navigation},
  name: 'TeamIntro',
  data() {
    return {
      images: [
        { src: '/images/hand_l.jpg', title: 'Online', show: false },
        { src: '/images/villa.jpg', title: 'In der Villa ten Hompel', show: false },
        { src: '/images/hand_r.jpg', title: 'Online', show: false },
      ],
      info: { text: 'Das machen wir heute.', show: false }
    }
  },
  computed: {
    ...mapGetters(['team'])
  },
  methods: {
    delayedShow(image, index) {
      let delay = 1500 * index
      setTimeout(() => {
        image.show = true
      }, delay)
    }
  },
  mounted() {
    this.images.forEach((image, index) => {
      this.delayedShow(image, index)
      if (index === this.images.length - 1) {
        this.delayedShow(this.info, index)
      }
    })
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.team-intro {
  width: 100%;
  margin: auto;
  @media (min-width: $tablet) {
    width: 40%;
  }
  .page-title {
    width: 100%;
    margin-bottom: 50px;
  }
  .info-wrapper {
    position: relative;
    margin-top: 70px;
    width: 80%;
    display: flex;
    justify-content: center;
    .info-text {
      font-size: 20px;
      margin-top: 70px;
      width: 60%;
      margin-left: 10px;
    }
  }

  .intro-images {
    display: flex;
    width: 100%;
    .intro-image {
      width: 33%;
      max-height: 100%;
      .image-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
        width: auto;
        .image-title {
          font-size: 20px;
          padding: 15px 15px 0 15px;
          text-align: center;
          min-height: 90px;
          display: flex;
          align-items: flex-end;
        }
      }

      &:not(:last-child) {
      border-right: 1px solid $darkBlue;
      }
    }
    img {
      width: 100%;
      max-height: 40vh;
      object-fit: cover;
      object-position: center;
    }
  }

}

</style>
