<template>
  <div class="imgemo-emoticons page-content">
    <img class="current-image" :src="src(currentImage.image.image)" >
    <span class="image-subtitle" v-html="currentImage.image.subtitle"></span>
    <div class="emoticons">
      <span class="emoticon" v-for="idx in 7" :key="idx">
        <img :src="emoticon(idx)" alt="icon" @click="select(idx)">
      </span>
    </div>
    <p v-html="imgemo.emoticon_text" class="emoticon-text"></p>
    <Navigation :disabled-next="!selected" @prev="prevPage" @next="nextPage" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {assetSrc} from '@/helpers'
import Navigation from '@/components/Navigation'
import {SELECT_EMOTICON} from '@/store/mutations'
import {SEND_IMGEMO} from '@/store/actions'

export default {
  name: 'ImgemoEmoticons',
  components: {Navigation},
  computed: {
    ...mapGetters(['selectedImages', 'currentImage', 'imgemo', 'prevImage', 'nextImage']),
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    src: assetSrc,
    prevPage() {
      if (typeof this.prevImage !== 'undefined') {
        this.$router.push({ name: 'selectEmoticon', params: { id: this.prevImage.image.id } })
      } else {
        this.$router.push({ name: 'imgemoSelected' })
      }
      this.selected = null
    },
    emoticon(idx) {
      const selected = this.currentImage.emoticon === idx && !this.selected
      if (selected) {
        this.selected = idx
      }
      const keyword = (selected) || (this.selected && this.selected === idx) ? 'active' : 'inactive'
      return require(`@/assets/icons/${idx - 1}_${keyword}.png`)
    },
    select(idx) {
      this.selected = idx
    },
    nextPage() {
      if (this.selected) {
        this.$store.commit(SELECT_EMOTICON, { imageId: this.currentImage.image.id, emoId: this.selected })
        if (typeof this.nextImage !== 'undefined') {
          this.$router.push({ name: 'selectEmoticon', params: { id: this.nextImage.image.id } })
        } else {
          this.$store.dispatch(SEND_IMGEMO)
          this.$router.push({ name: 'imgemoEnd' })
        }
        this.selected = null
      }
    },
  },
}
</script>

<style  lang="scss">
@import "src/styles/app";
.imgemo-emoticons {
  padding-bottom: 20px;
  .current-image {
    width: 100%;
    margin-bottom: 10px;
    max-height: 50vh;
    object-fit: contain;
    @media (min-width: $tablet) {
      width: 65%;
      max-height: 60vh;
    }
    @media (min-width: $desktop) {
      width: 55%;
    }
  }
  .image-subtitle {
    font-size: 14px;
  }
  .emoticons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 10px;
    max-width: 400px;
    @media (min-width: $smartphone) {
      width: 75%;
    }
    @media (min-width: $tablet) {
      width: 50%;
    }
    @media (min-width: $desktop) {
      width: 40%;
    }
    @media (min-width: $desktopLarge) {
      width: 30%;
    }
    .emoticon {
      width: 25%;
      display: block;
      padding: 6px 12px 0px;
      @media (min-width: $tablet) {
        padding: 12px 12px 0px;
      }
      img {
        max-width: 100%;
        cursor: pointer;
      }
    }
  }
  .emoticon-text {
    font-size: 20px;
    margin-top: 15px;
    padding: 0 25px;
    @media (min-width: $tablet) {
      margin-top: 25px;
    }
  }
}

</style>
