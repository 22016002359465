import axios from 'axios'
import urljoin from 'url-join'

axios.interceptors.request.use((config) => {
  if (typeof process.env.VUE_APP_API_URL !== 'undefined') {
    config.url = urljoin(process.env.VUE_APP_API_URL, config.url)
  }
  return config
})

export const getPageContent = async (seminarStage) => axios.get(`/data/${seminarStage}`)

export const sendImgemo = async ({data}) => {
  await axios.post('/imgemo', data)
}

export const sendPerspectives = async ({data}) => {
  await axios.post('/threep', data)
}

export const sendContactMessage = async ({data}) => {
  await axios.post('/contact', data)
}

export const sendAudioMessage = async ({data}) => {
  await axios.post('/audio', data,
    {headers: {'Content-Type': 'multipart/form-data'}})
}
export const sendTextMessage = async ({data}) => {
  await axios.post('/message', data)
}

export const subscribeEvent = async ({data}) => {
  await axios.post('subscribe', data)
}

