<template>
  <div class="team-video">
    <VideoPlayer
      :video="video"
      :poster="videoPoster"
      :subtitles="team.subtitles"
      @ended="closeVideo"
      @close="closeVideo"
      autoplay
    />
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'
import {mapGetters} from 'vuex'
export default {
  name: 'TeamVideo',
  components: {VideoPlayer},
  computed: {
    ...mapGetters(['team', 'isMobile']),
    video() {
      return this.isMobile ? this.team.video_mobile : this.team.video
    },
    videoPoster() {
      return this.isMobile ? this.team.video_mobile_poster : this.team.video_poster
    }
  },
  methods: {
    closeVideo() {
      this.$router.push({ name: 'teamEnd' })
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.team-video {
  display: flex;
  justify-content: center;
}
</style>