<template>
  <div class="threep-start page-content">
    <TeamInfo :image="threep.start_image" :title="threep.start_text" />
    <Navigation prev-page="imgemoEnd"  next-page="threepIntro"/>
  </div>
</template>

<script>
import TeamInfo from '@/components/TeamInfo'
import {mapGetters} from 'vuex'
import Navigation from '@/components/Navigation'
export default {
  name: 'ThreepStart',
  components: {Navigation, TeamInfo},
  computed: {
    ...mapGetters(['threep'])
  }
}
</script>

<style scoped>

</style>