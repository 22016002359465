<template>
  <div class="perspectives page-content">
    <div class="video-wrapper">
      <VideoPlayer
          v-for="(transition, idx) in transitions" :key="idx"
          :class="showPerspective(idx)"
          :videoId="idx + 1"
          :video="isMobile ? transition.video_mobile : transition.video"
          :poster="isMobile ? transition.video_mobile_poster : transition.video_poster"
          :autoplay="false"
          :controls="false"
          @ended="nextPerspective(idx + 1)"
      />
      <transition name="fade" mode="out-in">
        <div class="question" v-if="showSlider">
          <p class="question-text" v-html="perspective.question"></p>
          <Slider
              :left-option="perspective.param_1_left"
              :right-option="perspective.param_1_right"
              @change="change"
              :id="`p${parseInt(this.$route.params.id)}v1`"
              :value="v1"
          ></Slider>
          <Slider
              :left-option="perspective.param_2_left"
              :right-option="perspective.param_2_right"
              @change="change"
              :id="`p${parseInt(this.$route.params.id)}v2`"
              :value="v2"
          ></Slider>
        </div>
      </transition>
    </div>
    <Navigation @prev="prePerspective" @next="saveAndPlay(perspectiveId)" :disabledNext="!showNavi" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'
import {mapGetters} from 'vuex'
import Slider from '@/components/Slider'
import Navigation from '@/components/Navigation'
import {SET_RATINGS} from '@/store/mutations'
import {SEND_PERSPECTIVES} from '@/store/actions'
export default {
  name: 'ThreepPerspectives',
  components: {Navigation, Slider, VideoPlayer},
  data() {
    return {
      ratings: [],
      showSlider: true,
      showNavi: false,
      v1: 50,
      v2: 50
    }
  },
  computed: {
    ...mapGetters(['threep', 'transitions', 'isMobile', 'perspectivesRatings']),
    perspectiveId() {
      return parseInt(this.$route.params.id, 10)
    },
    perspective() {
      return this.threep.perspectives[this.perspectiveId - 1]
    },
    transition() {
      return this.transitions[this.perspectiveId - 1]
    },
    video() {
      return this.isMobile ? this.transition.video_mobile: this.transition.video
    },
  },
  created() {
    this.ratings = this.perspectivesRatings
    this.v1 = this.perspectivesRatings[this.perspectiveId - 1].points
    this.v2 = this.perspectivesRatings[this.perspectiveId].points
  },
  methods: {
    showPerspective(idx) {
      return this.perspectiveId === idx + 1 ? 'show' : ''
    },
    change(points, title, id) {
      if (this.ratings.length > 0) {
        const index = this.ratings.findIndex(r => r.id === id)
        this.ratings[index] = { points, id }
      }
      this.showNavi = true
    },
    nextPerspective(currId) {
      const video = document.getElementById(currId)
      video.load()
      if (this.perspectiveId <= 3) {
        this.showNavi = false
        this.$router.push({ name: 'perspectives', params: { id: (this.perspectiveId + 1).toString() } })
      }
    },
    prePerspective() {
      if (this.perspectiveId >= 2) {
        this.showNavi = false
        this.$router.push({ name: 'perspectives', params: { id: (this.perspectiveId - 1).toString() } })
      } else {
        this.$router.push({ name: 'theepQuestion' })
      }
    },
    saveAndPlay(id) {
      if (this.showNavi) {
        this.$store.commit(SET_RATINGS, this.ratings)
        if (this.perspectiveId === 3) {
          this.$store.dispatch(SEND_PERSPECTIVES)
          this.$router.push({ name: 'threepEnd' })
        } else {
          this.showSlider = false
          setTimeout(() => {
            this.showSlider = true
          }, 1000)
          const video = document.getElementById(id)
          video.play()
        }
      }
    },
  },
  watch: {
    $route(to) {
      const v1 = this.ratings.find(r => r.id === `p${parseInt(to.params.id)}v1`)
      if (typeof v1 !== 'undefined') {
        this.v1 = v1.points
      }
      const v2 = this.ratings.find(r => r.id === `p${parseInt(to.params.id)}v2`)
      if (typeof v2 !== 'undefined') {
        this.v2 = v2.points
      }
      if (this.v1 !== 50 || this.v2 !== 50) {
        this.showNavi = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/app";
.perspectives {
  .question {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    @media (min-width: $smartphone) {
      top: 70%;
    }
    @media (min-width: $smartphone) {
      top: 65%;
    }
    .slider {
      width: 100% !important;
    }
    .question-text {
      display: block;
      margin: 15px;
      font-size: 20px;
    }
  }
  .video-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    .video-player {
      opacity: 0;
      position: absolute;
      &.show {
        opacity: 1;
      }
      video {
        aspect-ratio: 1 / 1;
        max-height: 50vh;
        @media (min-width: $smartphone) {
          aspect-ratio: 3 / 2;
          max-height: 60vh;
        }
      }
      video[poster] {
        width: auto;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}

</style>