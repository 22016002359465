export const state = {
  page: {},
  seminarId: 0,
  seminarStage: '',
  seminarHash: '',
  participantId: null,
  token: null,
  tokenName: null,
  showMenu: false,
  pre_intro: {
    start_text: '',
    start_image: ''
  },
  notFound: {
    text: 'Diese Seite existiert nicht.'
  },
  team: {
    start_text: '',
    start_image: '',
    video: '',
    video_poster: '',
    video_mobile: '',
    video_mobile_poster: '',
    subtitles: [],
    end_text: '',
    end_image: ''
  },
  imgemo: {
    start_text: '',
    start_image: '',
    imagecategories: [],
    selected_text: '',
    selected_image: '',
    emoticon_text: '',
    end_text: '',
    end_image: ''
  },
  threep: {
    start_text: '',
    start_image: '',
    intro_text: '',
    intro_image: '',
    introvideo: '',
    introvideo_poster: '',
    introvideo_mobile: '',
    introvideo_mobile_poster: '',
    introvideo_subtitle: '',
    qustion_text: '',
    qustion_image: '',
    perspectives: [],
    transition12video: '',
    transition12video_poster: '',
    transition12video_mobile: '',
    transition12video_mobile_poster: '',
    transition23video: '',
    transition23video_poster: '',
    transition23video_mobile: '',
    transition23video_mobile_poster: '',
    perspective3_poster: '',
    perspective3_mobile_poster: ''
  },
  pre_end: {
    end_text: '',
    end_image: ''
  },
  isMobile: false,
  galleries: [],
  selectedImages: [],
  transitions: [],
  perspectivesRatings: [
    {points: 50, id: 'p1v1'},
    {points: 50, id: 'p1v2'},
    {points: 50, id: 'p2v1'},
    {points: 50, id: 'p2v2'},
    {points: 50, id: 'p3v1'},
    {points: 50, id: 'p3v2'},
  ],
  staticPages: [],
  post_message: {
    video: '',
    video_poster: ''
  },
  events: {
    thank_text: '',
    thank_image: '',
    events_text: '',
    events: []
  },
  social: {},
  subscribedEvents: [],
  messageScreenShow: false,
  messageScreenType: 'success',
  lastRoute: ''
}
