<template>
  <transition name="fade">
    <div class="confirm" v-if="messageScreenShow">
      <h1 v-html="text"></h1>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex'
import {TOGGLE_MESSAGE_SCREEN} from '@/store/mutations'

export default {
  name: 'Confirm',
  computed: {
    ...mapGetters(['messageScreenShow', 'messageScreenType']),
    text() {
      return this.messageScreenType === 'error' ? this.error : this.success
    }
  },
  data() {
    return {
      success: 'Ihre Nachricht wurde gesendet',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch ein mal.',
    }
  },
  watch: {
    messageScreenShow(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit(TOGGLE_MESSAGE_SCREEN)
        }, 2000)
      }
    }
  }
}
</script>

<style  lang="scss">
@import "src/styles/app";
.confirm {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $darkBlue;
  color: $lightBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 9999;
  h1 {
    text-align: center;
  }
}

</style>