<template>
  <div class="post-message page-content">
    <VideoPlayer
      :video="post_message.video"
      :poster="post_message.video_poster"
      @close="closeVideo"
      @ended="closeVideo"
      :autoplay="true"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VideoPlayer from '@/components/VideoPlayer'
export default {
  name: 'PostMessage',
  components: {VideoPlayer},
  computed: {
    ...mapGetters(['post_message', 'seminarStage'])
  },
  methods: {
    closeVideo() {
      this.$router.push({ name: 'sendMessage' })
    }
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'post' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style scoped>

</style>