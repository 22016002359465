<template>
  <div class="team-page">
    <router-view />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Team',
  computed: {
    ...mapGetters(['seminarStage'])
  },
  watch: {
    seminarStage (stage) {
      if (stage !== 'pre' && stage === 'invalid') {
        this.$router.push('/404')
      }
    },
  }
}
</script>

<style scoped>

</style>