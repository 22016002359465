import * as makeSlug from 'slugify'

export const assetSrc = (src) => {
  if (typeof src !== 'undefined') {
    const stage = process.env.VUE_APP_API_HOST || 'indeep.voelzow.de'
    return document.location.hostname !== stage && src.indexOf('http') === -1 ?
      `https://${stage}/${src}`.replace(/([^:]\/)\/+/g, '$1') : src
  } else {
    console.error('Error while fetching media!')
  }
}

export const slugify = (string) => makeSlug(string, { lower: true, locale: 'de' })
